window.bhd ||= {}

bhd.specialist_map =
  api_key: "AIzaSyAREpJwNlOlSHJKu5berF25fjCVoC7DnJk"
  map_elements: document.querySelectorAll('.specialist_map_element')
  styles: [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f2fafb"
        },
        {
          "lightness": -10
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#27384b"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f2fafb"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "labels",
      "stylers": [
        {
          "lightness": -10
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#525f6f"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#525f6f"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f2fafb"
        },
        {
          "lightness": -10
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text",
      "stylers": [
        {
          "lightness": 40
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "lightness": 40
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#27384b"
        },
        {
          "saturation": 25
        },
        {
          "lightness": 35
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#27384b"
        },
        {
          "lightness": 10
        }
      ]
    },
    {
      "featureType": "transit.station.airport",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": -25
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#009ca6"
        },
        {
          "lightness": -15
        }
      ]
    }
  ]

  setup: ->
    if bhd.specialist_map.map_elements.length == 0
      return false

    !((d, s, id)->
      fjs = d.getElementsByTagName(s)[0]
      if !d.getElementById(id)
        js = d.createElement(s)
        js.id = id
        js.src = "https://maps.googleapis.com/maps/api/js?key=#{bhd.specialist_map.api_key}&callback=bhd.specialist_map.initialize"
        fjs.parentNode.insertBefore(js,fjs)
      else
        bhd.specialist_map.initialize()
    )(document,"script","gmap_loader")

    @add_listeners()

  add_listeners: ->
    url = window.location.search
    params = new URLSearchParams(url)
    if params.get('filter') && params.get('filter').length
      document.querySelector('body').classList.add('is_filtered')

    document.querySelector('.specialist_map_filter_link').addEventListener 'click', (e) ->
      e.preventDefault()
      document.querySelector('.specialist_map_filter').classList.add('open')

    document.querySelector('.specialist_map_filter_close_link').addEventListener 'click', (e) ->
      e.preventDefault()
      document.querySelector('.specialist_map_filter').classList.remove('open')

  initialize: ->
    @setup_maps()

  setup_maps: ->
    bhd.specialist_map.map_elements.forEach (location, idx) ->
      pin = location.getAttribute('data-icon')
      map = bhd.specialist_map.create_map(location)
      bounds = new google.maps.LatLngBounds()

      oms = new OverlappingMarkerSpiderfier(map, {
        markersWontMove: true,
        markersWontHide: true,
        keepSpiderfied: true,
        circleFootSeparation: 30
      })

      document.querySelectorAll('.specialist_stub_item').forEach (stub, idx) ->
        return unless stub.getAttribute('data-lat') && stub.getAttribute('data-lng')

        colour = stub.getAttribute('data-colour')
        map_pin = window.btoa(pin.replace('#00838c', colour))
        marker = new google.maps.Marker({
          position: { lat: parseFloat(stub.getAttribute('data-lat')), lng: parseFloat(stub.getAttribute('data-lng')) },
          icon:
            url: "data:image/svg+xml;base64,#{map_pin}",
          map: map
        })

        stub.querySelector('.specialist_stub_close_link').addEventListener 'click', (e) ->
          e.preventDefault()
          stub.style.display = 'none'
          document.querySelector('body').classList.remove('show_specialist')

        marker.addListener 'spider_click', ->
          document.querySelectorAll('.specialist_stub_item').forEach (stub) ->
            stub.style.display = 'none'

          stub.style.display = 'block'

          document.querySelector('body').classList.add('show_specialist')

          stub_element_height = stub.offsetHeight
          document.querySelector('.specialist_map_filter_link').style.bottom = "#{stub_element_height + 32}px"

        oms.addMarker(marker)
        bounds.extend(marker.getPosition())

      if window.matchMedia('(max-width: 849px)').matches
        boundsOptions = {
          top: 84,
          right: 0,
          bottom: 80,
          left: 0
        }
      else
        boundsOptions = {
          top: 0,
          right: 0,
          bottom: 0,
          left: 202
        }

      map.fitBounds(bounds, boundsOptions)

  create_map: (location) ->
    options =
      center: new google.maps.LatLng(location.getAttribute('data-lat'), location.getAttribute('data-lng'))
      mapTypeId: google.maps.MapTypeId.ROADMAP
      maxZoom: 18
      scrollwheel: true
      draggable: true
      zoomControl: false
      cameraControl: false
      mapTypeControl: false
      streetViewControl: false
      fullscreenControl: false
      styles: bhd.specialist_map.styles

    if window.matchMedia('(max-width: 849px)').matches
      options['cameraControl'] = false
    else
      options['cameraControl'] = true

    new google.maps.Map(location, options)
